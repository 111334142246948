import { useMatches } from '@remix-run/react';

import HandleIds from '~/constants/handleIds';

import type { RouteTyping } from 'types';

// use this hook to get the user data from within an authenticated route ie this assumes the user data is there and should not be used for auth.
export default function useGetUser() {
  const matches = useMatches();

  let data: RouteTyping<'App'> | RouteTyping<'Mixtape'> = matches.find(m => m.handle?.id === HandleIds.App)
    ?.data as RouteTyping<'App'>;

  if (!data) data = matches.find(m => m.handle?.id === HandleIds.Mixtape)?.data as RouteTyping<'Mixtape'>;

  return (data as RouteTyping<'App'>)?.user?.data;
}

// use this one for routes outside _app, but inside mixtapes/
export function useGetUserForMixtapes() {
  const matches = useMatches();

  return (matches.find(m => m.handle?.id === HandleIds.Mixtape)?.data as RouteTyping<'Mixtape'>)?.user?.data;
}

export function useGetUserOnPublicRoute() {
  const matches = useMatches();

  let data: RouteTyping<'App'> | RouteTyping<'Mixtape'> = matches.find(m => m.handle?.id === HandleIds.App)
    ?.data as RouteTyping<'App'>;

  if (!data) data = matches.find(m => m.handle?.id === HandleIds.Mixtape)?.data as RouteTyping<'Mixtape'>;

  if (!data) return null;

  return data?.user?.data;
}
